<template>
  <div class="orderTest-container">
    <div class="top">
      <el-tabs v-model="activeName" type="card" style="margin-right: 74px">
        <el-tab-pane label="小包装" name="1"></el-tab-pane>
        <el-tab-pane label="中包装" name="2"></el-tab-pane>
        <el-tab-pane label="大包装" name="3"></el-tab-pane>
      </el-tabs>
      <el-button
        type="primary"
        style="width: 100px; margin-left: 95px"
        @click="add"
      >
        添加
      </el-button>
    </div>
    <div class="table-wrapper">
      <el-table
        stripe
        :data="tableData"
        style="width: 520px; margin-top: 10px"
        row-key="id"
        border
      >
        >
        <!-- 序号 -->
        <el-table-column
          align="center"
          type="index"
          width="50"
        ></el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="50"
        ></el-table-column>
        <!-- 排序 -->
        <el-table-column label="排序" align="center" width="80px">
          <template #default="{ row }">
            <el-input
              v-model.trim="row.sort"
              @change="blur_(row.id, row.sort)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="单位名称"
          prop="unit_name"
          width="auto"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" prop="" width="120px" align="center">
          <template #default="{ $index, row }">
            <el-button type="text" @click.native.prevent="editRow($index, row)">
              修改
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="deleteRow($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加 -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :title="addUnitTitle"
      width="550px"
      :visible.sync="showDialog"
      top="25vh"
    >
      <div class="content">
        <el-form
          ref="addForm"
          inline
          style="text-align: center"
          :model="addForm"
          :rules="rules"
        >
          <el-form-item label="单位名称" prop="unit_name">
            <el-input v-model="addForm.unit_name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitNew">添加并新建</el-button>
        <el-button type="primary" @click="justSubmit">添加</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      :title="editUnitTitle"
      width="550px"
      :visible.sync="showEditDialog"
      top="25vh"
    >
      <div class="content">
        <el-form
          ref="editForm"
          inline
          style="text-align: center"
          :model="editForm"
          :rules="rules"
        >
          <el-form-item label="单位名称" prop="unit_name">
            <el-input v-model="editForm.unit_name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editSubmit">保存</el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import _ from 'lodash'
  import {
    getTableList,
    deleteRow,
    refreshSort,
    creatUnit,
    editRow1,
  } from '@/api/units'
  export default {
    name: 'Units',
    data() {
      return {
        activeName: '1',
        addUnitTitle: '新增小包装单位',
        editUnitTitle: '修改小包装单位',
        addForm: {
          unit_name: '',
        },
        editForm: {
          unit_name: '',
        },
        showDialog: false,
        showEditDialog: false,
        tableData: [],
        rules: {
          unit_name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
            {
              min: 1,
              max: 10,
              message: '长度在 1 到 10 个字符',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    watch: {
      activeName(val) {
        this.fetchData()
        if (val == '1') {
          this.addUnitTitle = '新增小包装单位'
          this.editUnitTitle = '修改小包装单位'
        } else if (val == '2') {
          this.addUnitTitle = '新增中包装单位'
          this.editUnitTitle = '修改中包装单位'
        } else if (val == '3') {
          this.addUnitTitle = '新增大包装单位'
          this.editUnitTitle = '修改大包装单位'
        }
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      // 获取表格数据
      async fetchData() {
        let { data } = await getTableList({ unit_type: this.activeName })
        this.tableData = data
        console.log(data)
      },
      submitNew() {
        this.submit(0)
      },
      justSubmit() {
        this.submit(1)
      },
      editSubmit() {
        // this.submit(1, 'editForm')
        if (this.editForm.unit_name) {
          let subData = {
            id: this.editId,
            unit_name: this.editForm.unit_name,
            unit_type: this.activeName,
          }
          console.log(subData)
          editRow1(subData).then((res) => {
            this.$message.success(res.msg)
            this.fetchData()
            this.showEditDialog = false
          })
        } else {
          this.$message.error('请输入单位')
        }
      },
      submit(addnew = 0, form = 'addForm') {
        let subData = {
          unit_name: this.addForm.unit_name,
          unit_type: this.activeName,
        }
        console.log(form)
        console.log(subData)
        this.$refs[form].validate((val) => {
          if (val) {
            creatUnit(subData).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.addForm.unit_name = ''
                if (addnew) {
                  this.showDialog = false
                }
                this.fetchData()
              } else if (res.code == 1) {
                this.$message.error(res.msg)
                this.addForm.unit_name = ''
              }
            })
          } else {
            console.log('验证失败')
            return false
          }
        })
      },
      close() {
        this.showDialog = false
        this.showEditDialog = false
      },
      add() {
        this.showDialog = true
      },
      // 排序输入框失去焦点 排序
      blur_(rowId, rowSort) {
        refreshSort({
          id: rowId,
          sort: rowSort,
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success('排序成功')
            this.fetchData()
          }
        })
      },
      editRow(index, row) {
        console.log('editRow')
        console.log(index)
        console.log(row)
        let a = JSON.parse(JSON.stringify(row))
        this.editForm = a
        this.editId = row.id
        this.showEditDialog = true
      },
      deleteRow(index, row) {
        console.log('deleteRow')
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteRow({ id: row.id }).then((res) => {
              console.log(res, '删除')
              if (res.code == 200) {
                this.$message.success('删除成功')
                this.fetchData()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-tabs__header {
    margin: 0;
  }
  .top {
    display: flex;
    height: 40px;
    line-height: 40px;
  }
  .content {
    padding-top: 20px;
  }
</style>
