var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "el-tabs",
            {
              staticStyle: { "margin-right": "74px" },
              attrs: { type: "card" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "小包装", name: "1" } }),
              _c("el-tab-pane", { attrs: { label: "中包装", name: "2" } }),
              _c("el-tab-pane", { attrs: { label: "大包装", name: "3" } }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "100px", "margin-left": "95px" },
              attrs: { type: "primary" },
              on: { click: _vm.add },
            },
            [_vm._v(" 添加 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "520px", "margin-top": "10px" },
              attrs: {
                stripe: "",
                data: _vm.tableData,
                "row-key": "id",
                border: "",
              },
            },
            [
              _vm._v(" > "),
              _c("el-table-column", {
                attrs: { align: "center", type: "index", width: "50" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", type: "selection", width: "50" },
              }),
              _c("el-table-column", {
                attrs: { label: "排序", align: "center", width: "80px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          on: {
                            change: function ($event) {
                              return _vm.blur_(row.id, row.sort)
                            },
                          },
                          model: {
                            value: row.sort,
                            callback: function ($$v) {
                              _vm.$set(
                                row,
                                "sort",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.sort",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单位名称",
                  prop: "unit_name",
                  width: "auto",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  prop: "",
                  width: "120px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.editRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 修改 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.deleteRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: _vm.addUnitTitle,
            width: "550px",
            visible: _vm.showDialog,
            top: "25vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "text-align": "center" },
                  attrs: { inline: "", model: _vm.addForm, rules: _vm.rules },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位名称", prop: "unit_name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.unit_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "unit_name", $$v)
                          },
                          expression: "addForm.unit_name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitNew } },
                [_vm._v("添加并新建")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.justSubmit } },
                [_vm._v("添加")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: _vm.editUnitTitle,
            width: "550px",
            visible: _vm.showEditDialog,
            top: "25vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEditDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "editForm",
                  staticStyle: { "text-align": "center" },
                  attrs: { inline: "", model: _vm.editForm, rules: _vm.rules },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位名称", prop: "unit_name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editForm.unit_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "unit_name", $$v)
                          },
                          expression: "editForm.unit_name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.editSubmit } },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }