/*
 * @Author: your name
 * @Date: 2020-11-11 14:09:29
 * @LastEditTime: 2020-11-12 11:01:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\api\units.js
 */
import request from '@/utils/request'

// table
export function getTableList(data) {
  return request({
    url: '/baseAdmin/unit/index',
    method: 'post',
    data,
  })
}

// 创建单位
export function creatUnit(data) {
  return request({
    url: '/baseAdmin/unit/create',
    method: 'post',
    data,
  })
}
// 删除
export function deleteRow(data) {
  return request({
    url: '/baseAdmin/unit/delete',
    method: 'post',
    data,
  })
}
// 排序
export function refreshSort(data) {
  return request({
    url: '/baseAdmin/unit/sort',
    method: 'post',
    data,
  })
}
// 修改
export function editRow(data) {
  return request({
    url: '/baseAdmin/brand/update',
    method: 'post',
    data,
  })
}
export function editRow1(data) {
  return request({
    url: '/baseAdmin/unit/update',
    method: 'post',
    data,
  })
}
